import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"
import Card from "../components/card/card";

const tcPdf = <IFrame file="../TC-Plataforma.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
const footerDesc = "<p>EVVA Plataforma S.A.P.I. de C.V. (EVVA) se dedica exclusivamente al desarrollo de la plataforma tecnológica (La Plataforma) a través de la cual usted puede adquirir y administrar servicios financieros empresariales y otorgando el uso de las misma a aquellas entidades financieras con las que se establezca relaciones comerciales o corporativas en términos de la legislación y normatividad aplicable. Por lo anterior, EVVA es únicamente el intermediario tecnológico entre el usuario de La Plataforma y dichas instituciones financieras otorgando derecho al uso de la misma, en el entendido que (i) EVVA no ofrece servicios financieros, (ii) EVVA no presta actividades financieras o actividades que se encuentren reguladas o supervisadas por autoridad alguna. (iii) Las entidades financieras con las que se establezca una relación comercial son entidades autorizadas por el Gobierno Federal y, por tanto, las únicas responsables de las operaciones financieras que celebre con sus clientes, aun cuando ello suceda a través de la Plataforma.</p>";
const contentMaxWidth = "980px";

const Identidad = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const launchFormHandler = () => {
        window.open("https://evvafinanzas.typeform.com/to/fmKiFH3T", "blank");
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} title="KYC & AML | Verifica la identidad de tus clientes en línea." description="Sólo clientes legítimos, verifica la identidad de tus clientes para que puedas prevenir ataques de estafadores, cumple con las regulaciones de KYC y AML"/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
            }
            <MainSection
                title="Verifica la identidad de tus clientes en línea."
                description="<h3>Sólo clientes legítimos, verifica la identidad de tus clientes previniendo ataques de estafadores, cumple con las regulaciones de KYC y AML.</h3>"
                textColor="primary"
                position="left"
                image="hero-rem.png"
                actionLabel="Habla con Nosotros"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickLearnEVVArem"
            >
                <AppBar pathname={appState.path}/>
            </MainSection>
            <PageSection
                title="Existen nuevos retos en un mundo digital"
                maxColumns="4"
            >
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="reduce-scammers-icon.png"
                    description="<p>Existen estafadores e infractores en tu flujo de negocio o plataforma. Garantiza la legitimidad y confianza en tus clientes.</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="help-team-risk-icon.png"
                    description="<p>Operación manual. Empodera a tu equipo de riesgo. Verifica la identidad de forma segura, y determina si un usuario es legítimo o fraudulento.</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="easy-prove-identity.png"
                    description="<p>Fricción en tus procesos. Facilita a tus usuarios la prueba de su identidad y, al mismo tiempo, cumple con una tu requisitos de cumplimiento.</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="security-layer-icon.png"
                    description="<p>Pérdidas por fraude. Integra una capa de seguridad antes de permitir funciones de alto riesgo o si observas actividades sospechosas.</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
            </PageSection>
            <PageSection title="Te presentamos Identidad Digital REM" description="Basado en la tecnología Facetec, utilizada para verificar la identidad digital de millones de usuarios globales, una parte fundamental de tu proceso de negocio para conocer a tus clientes (KYC ), y reducir fraude en tus operaciones" maxWidth={contentMaxWidth}>
                <Card
                    title="Biometría"
                    description="<p>Compara la foto de identificaciones oficiales con selfies del titular del documento, y protege tu plataforma de estafadores con nuestros análisis biométricos de movimiento y de rostro.</p>"
                    descriptionColor="#363636"
                    image="rem-2.png"
                    imageWidth="260px"
                    imageHeight="300px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
               <Card
                    title="Verificación de IDs y Bases Gubernamentales"
                    description="<p>Verifica cualquier documento de identidad o bases gubernamentales en menos de 1 minuto: INE, Pasaportes, RENAPO, SAT, y garantiza que tus usuarios realmente sean ciudadanos.</p>"
                    descriptionColor="#363636"
                    image="rem-3.png"
                    imageWidth="260px"
                    imageHeight="270px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
                <Card
                    title="Verificaciones avanzadas"
                    description="<p>Reconocimiento OCR INE, Comprobante de domicilio: Telmex y CFE Constancia de situación fiscal, Recibos de nómina y facturas. Validación de Domicilio y ubicación, verificación de teléfonos y correos, validación cuenta bancarias y buró de crédito.</p>"
                    descriptionColor="#363636"
                    image="rem-4.png"
                    imageWidth="260px"
                    imageHeight="230px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />       
                <Card
                    title="¿Cómo funciona?"
                    description="<p>Hemos creado workflows y APIs que puedes conectar directamente a tu plataforma en cuestión de días.</p>"
                    descriptionColor="#363636"
                    image="rem-1.png"
                    imageWidth="260px"
                    imageHeight="280px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
                
                
                
            </PageSection>
            <SecondarySection
                title="Con EVVA REM"
                shortDescription="Crea una comunidad de clientes legítimos con nuestras soluciones de identidad digital."
                position="center"
                actionLabel="Habla con Nosotros"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickLearnEVVArem"
            >
                <div style={{margin: "auto auto 24px", maxWidth: "780px"}}>
                    <img src="../rem-image.png" width="100%" alt="EVVA REM"/>
                </div>
            </SecondarySection>
        </Layout>
    )
};

export default Identidad;
